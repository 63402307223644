import { SelectableValue } from '@grafana/data';

export const SELECTED_STATETIME_RANGE = 'grafana.panel.statetimeline.range';
export const PRODUCTION_PLUGIN_BASE_URL = '/api/plugins/lesly-production-app/settings';

export const generateOptions = (desc = false) => {
  const values = [
    { label: 'Contrôle 1er article en Zeiss', description: 'SCHEDULED' },
    { label: 'Mise au Point Industrialisation', description: 'SCHEDULED' },
    { label: 'Absence opérateur: congé', description: 'SCHEDULED' },
    { label: 'Absence opérateur: réunion', description: 'SCHEDULED' },
    { label: 'Préparation changement de série', description: 'SCHEDULED' },
    { label: 'Cycle de chauffe', description: 'SCHEDULED' },
    { label: 'Maintenance 1er niveau', description: 'SCHEDULED' },
    { label: 'Maintenance préventive', description: 'SCHEDULED' },
    { label: 'Rupture outil', description: 'UNSCHEDULED' },
    { label: 'Rupture approvionnement matière', description: 'UNSCHEDULED' },
    { label: 'Rupture approvionnement outil', description: 'UNSCHEDULED' },
    { label: 'Sous charge', description: 'UNSCHEDULED' },
    { label: 'Absence Moyen de contrôle', description: 'UNSCHEDULED' },
    { label: 'Absence Opérateur: maladie', description: 'UNSCHEDULED' },
    { label: 'Absence Opérateur: retard', description: 'UNSCHEDULED' },
    { label: 'Problème qualité', description: 'NO_QUALITY' },
    { label: 'Défaut produit', description: 'NO_QUALITY' },
    { label: 'Défaut pièce', description: 'NO_QUALITY' },
  ];

  return values.map<SelectableValue<string>>((inc) => ({
    value: inc.label
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toUpperCase()
      .split(' ')
      .join('_')
      .split(':')
      .join(''),
    label: inc.label,
    description: inc.description,
  }));
};
