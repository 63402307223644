import { uniqBy } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';

import { AppEvents, SelectableValue, DateTime, dateTime } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import appEvents from 'app/core/app_events';

import { PRODUCTION_PLUGIN_BASE_URL, SELECTED_STATETIME_RANGE, generateOptions } from './constants';

export const useIncidentViewModel = () => {
  const [incident, setIncident] = useState<string>('');
  const [incidentGroup, setIncidentGroup] = useState<string>('');
  const [isIncidentOpen, setIsIncidentOpen] = useState<boolean>(false);
  const [isIncidentSelected, setIsIncidentSelected] = useState<boolean>(false);
  const [incidentOptions, setIncidentOptions] = useState<Array<SelectableValue<string>>>(generateOptions());
  const [openTime, setOpenTime] = useState<string>('8');
  const [teamNumber, setTeamNumber] = useState<string>('3');
  const [pauseTime, setPauseTime] = useState<string>('1');
  const [partsByHour, setPartsByHour] = useState<string>('1');
  const [comment, setComment] = useState<string>('');
  const [nbrDefaultPart, setNbrDefaultPart] = useState<string>('0');
  const [isDefaultPartSelected, setIsDefaultPartSelected] = useState<boolean>(false);
  const today = new Date();
  const [startDate, setStartDate] = useState<DateTime>(dateTime(today));
  const [endDate, setEndDate] = useState<DateTime>(dateTime(today));
  const [minDate, setMinDate] = useState<Date>(today);
  const [maxDate, setMaxDate] = useState<Date>(today);

  const onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNbrDefaultPart(event.target.value);
  };

  const fetchIncidents = async () => {
    try {
      const prodConfigJsonData = await getBackendSrv().get(PRODUCTION_PLUGIN_BASE_URL);
      if (prodConfigJsonData.jsonData) {
        const incidentList = JSON.parse(prodConfigJsonData.jsonData?.incidentList);
        const mergedIncidentsList = [...incidentOptions, ...incidentList];
        const uniqIncidents = uniqBy(mergedIncidentsList, (v) => v.value);
        setIncidentOptions([...uniqIncidents]);
        setOpenTime(prodConfigJsonData.jsonData?.openTime);
        setTeamNumber(prodConfigJsonData.jsonData?.teamNumber);
        setPauseTime(prodConfigJsonData.jsonData?.pauseTime);
        setPartsByHour(prodConfigJsonData.jsonData?.partsByHour);
      }
    } catch (error) {
      console.error('Error on read production parameters:', error);
    }
  };

  const onSelectIncident = useCallback((selectable: SelectableValue<string>) => {
    if (selectable.value !== undefined && selectable.description !== undefined) {
      setIncident(selectable.value);
      setIncidentGroup(selectable.description);
      setIsIncidentSelected(true);
      if (selectable.description === 'NO_QUALITY') {
        setIsDefaultPartSelected(true);
      } else {
        setIsDefaultPartSelected(false);
        setNbrDefaultPart('0');
      }
    }
  }, []);

  const onOpenIncident = () => {
    setIsIncidentOpen(true);
    setIsIncidentSelected(false);
    const selectedTSRange = localStorage.getItem(SELECTED_STATETIME_RANGE);
    if (selectedTSRange) {
      const selectedTSRangeJson = JSON.parse(selectedTSRange);
      setStartDate(dateTime(selectedTSRangeJson.startDate));
      setEndDate(dateTime(selectedTSRangeJson.endDate));
      setMinDate(new Date(selectedTSRangeJson.startDate));
      setMaxDate(new Date(selectedTSRangeJson.endDate));
    }
    fetchIncidents();
  };

  const onCloseIncident = () => {
    setIsIncidentOpen(false);
  };

  const onSaveIncident = useCallback(async () => {
    let host = window.location.host;
    let incidentApiUrl: string;

    const selectedTSRange = localStorage.getItem(SELECTED_STATETIME_RANGE);
    // const appFrontSettings = await getBackendSrv().get('/api/frontend/settings');
    if (host.includes(':')) {
      host = host.split(':')[0];
    }
    incidentApiUrl = `http://${host}:9997/api/v1/incidents`;
    if (host.includes('lesly')) {
      incidentApiUrl = `https://${host}/api/v1/incidents`;
    }

    if (selectedTSRange === null) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: ['Cannot add new incident for this intervall with no end date'],
      });
    }

    const newStarDate = new Date(startDate.valueOf()).getTime();
    const newEndDate = new Date(endDate.valueOf()).getTime();
    const newDuration = newEndDate - newStarDate;

    if (selectedTSRange && newEndDate > newStarDate) {
      const selectedTSRangeJson = JSON.parse(selectedTSRange);
      const incidentBody = {
        incident: incident,
        incidentDates: {
          sourceIncidentSerie: selectedTSRangeJson.sourceIncidentSerie,
          sourceIncidentLabel: selectedTSRangeJson.sourceIncidentLabel,
          startDate: newStarDate,
          endDate: newEndDate,
          duration: newDuration,
        },
        openTime: openTime,
        teamNumber: teamNumber,
        uiSource: 'timeline-panel',
        dateType: 'START',
        incidentGroup: incidentGroup,
        pauseTime: pauseTime,
        partsByHour: partsByHour,
        comment: comment || 'NO_COMMENT',
        nbrDefaultPart: nbrDefaultPart,
      };

      let reqOptions = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(incidentBody),
      };
      try {
        const response = await fetch(incidentApiUrl, reqOptions);
        if (response.ok) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: ['New incident added' + ': ' + response.status + ' (' + response.statusText + ')'],
          });
        }
      } catch (error) {
        console.error('Error on added incident:', error);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [
            'Cannot add new incident: check  production plugin parameters or check input date intervall values',
          ],
        });
      }
    }

    onCloseIncident();
  }, [
    comment,
    endDate,
    incident,
    incidentGroup,
    nbrDefaultPart,
    openTime,
    partsByHour,
    pauseTime,
    startDate,
    teamNumber,
  ]);

  return {
    incident,
    isIncidentOpen,
    incidentOptions,
    isIncidentSelected,
    isDefaultPartSelected,
    nbrDefaultPart,
    comment,
    onSelectIncident,
    onOpenIncident,
    onSaveIncident,
    onCloseIncident,
    fetchIncidents,
    onChange,
    onTextChange,
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    minDate,
    maxDate,
  };
};
